import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ReactGA from 'react-ga'
import { submitUmamiEvent } from '../tools'

export const Services = ({ data }) => {
  const isMobile = useMediaQuery('(max-width:770px)')

  const addListener = () => {
    var iframe = document.createElement('iframe')
    iframe.style.display = 'block'
    iframe.src =
      'https://booksy.com/widget/index.html?country=us&lang=en&id=429672&uniqueId=429672-29&mode=inline&theme=lite-booksy-com'
    iframe.id = 'frame'
    document.getElementById('frameContainer').appendChild(iframe)
    const frame = document.getElementById('frame')

    window.addEventListener(
      'message',
      function (e) {
        e && e.data.events && (frame.style.height = e.data.events.resize.height + 'px')
      },
      false
    )

    iframe.onload = () => {
      document.getElementById('frameSkeleton').style.backgroundColor = 'rgba(0, 0, 0, 0)'
      document.getElementById('frameSkeleton').style.height = '0'
      setTimeout(() => {
        document.getElementById('frameSkeleton').style.display = 'none'
      }, 500)
    }
  }

  return (
    <Grid item xs={12} className='section'>
      <Grid container className='container'>
        <Grid item xs={12} className='centered'>
          <img
            alt=''
            style={isMobile ? { marginTop: '15px', marginBottom: '15px' } : { marginTop: '50px', marginBottom: '50px' }}
            className='divider'
            src='./img/scissors.svg'
          />
          <Typography component='h2'>
            {' '}
            {data.texts.filter(text => text.position === 'divider-one')[0].content}{' '}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <hr />
          <Grid container>
            <table>
              <tbody>
                <tr>
                  <th> Service: </th>
                  <th> Price: </th>
                </tr>
                {data.texts
                  .filter(text => text.type === 'table')
                  .map((item, index) => {
                    return (
                      <tr key={item.content}>
                        <td
                          key={item.content}
                          style={{
                            borderBottom:
                              index === data.texts.filter(text => text.type === 'table').length - 1 && '0px',
                          }}
                        >
                          {item.content}
                        </td>
                        <td
                          key={item.price}
                          style={{
                            borderBottom:
                              index === data.texts.filter(text => text.type === 'table').length - 1 && '0px',
                          }}
                        >
                          {item.price}
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </Grid>
          <hr />
        </Grid>

        <Grid item xs={12} className='centered'>
          <Typography component='h2' style={{ padding: '0px 50px' }}>
            {' '}
            {data.texts.filter(text => text.position === 'divider-two')[0].content}{' '}
          </Typography>
          <Button
            style={{ margin: '0px auto', maxWidth: '90vw' }}
            variant='outlined'
            size='large'
            onClick={() => {
              document.getElementById('book-dialog').style.display = 'flex'
              addListener()
              ReactGA.event({ category: 'Click', action: 'Clicked book button from services section' })
              submitUmamiEvent('clicked-book-button', {})
            }}
          >
            {' '}
            Book Now{' '}
          </Button>{' '}
          <br />
          <img alt='' style={{ marginTop: '50px', marginBottom: '-100px' }} className='divider' src='./img/razor.svg' />
        </Grid>
      </Grid>
    </Grid>
  )
}
