import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { Grid } from '@material-ui/core'

const PHOTOS = gql`
  query Document($id: String!) {
    document(id: $id) {
      data
    }
  }
`

const PhotoSection = () => {
  const {
    loading,
    error,
    data: photosData,
  } = useQuery(PHOTOS, {
    variables: {
      id: '8de5c489-2227-47b3-b007-fd21be05eb2a',
    },
  })
  if (loading) return 'loading'
  if (error) return 'error'

  const photos = photosData?.document?.data

  // const parsedPhotos = JSON.parse(photos)

  const data = photos

  return (
    <Grid item xs={12} className='photo-section section'>
      <Grid container className='container'>
        {data &&
          data.photos.map(photo => {
            return (
              <Grid
                item
                sm={5}
                md={4}
                key={photo.position}
                className='photo'
                style={{
                  backgroundRepeat: 'no-repeat',
                  background: 'url(' + photo.url + ')',
                  backgroundSize: 'cover',
                }}
              />
            )
          })}
      </Grid>
    </Grid>
  )
}

export default PhotoSection
