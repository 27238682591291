export const submitUmamiEvent = (eventName, data) =>
  window?.umami?.track(eventName, {
    ...data,
    ...getBrowserData(),
  })

export const getBrowserData = () => {
  try {
    return {
      oscpu: window.navigator.oscpu || 'not supported',
      screensize: `${window.screen.height}x${window.screen.width}`,
      viewportSize: `${window.innerHeight}x${window.innerWidth}`,
      userAgent: window.navigator.userAgent,
      userAgentBrands:
        (window.navigator.userAgentData &&
          window.navigator.userAgentData &&
          window.navigator.userAgentData.brands.map(brand => brand.brand).join(',')) ||
        'not supported',
      platform: window.navigator.platform || 'not supported',
      languages: (window.navigator.languages && window.navigator.languages.join(',')) || 'not supported',
      variant: localStorage.getItem('CBL_EXPERIMENT_VAR') || 'not available',
      experimentData: localStorage.getItem('experimentObject') || 'not available',
    }
  } catch {
    return null
  }
}
