import { createTheme } from '@material-ui/core/styles'

export const milanoTheme = createTheme({
  name: 'driveway',
  palette: {
    primary: {
      main: '#B92C3C',
      contrastText: '#f9f9f9',
      titleText: '#363636',
      subtitleText: '#4a4a4a',
      captionText: '#555555'
    },
    secondary: {
      main: '#1E1E20',
      light: '#333436',
      contrastText: '#4a4a4a',
      contrastTextLight: '#8a8a8a'
    },
    background: {
      default: '#1E1E20'
    }
  },
  typography: {
    navLink: {
      hover: '#3273dc',
    },
    h1: {
      fontSize: '42px',
      fontWeight: '700',
      lineHeight: 1.117,
      letterSpacing: '-0.01562em',
      fontFamily: 'Montserrat',
      marginTop: '3.5rem',
      sm: {
        fontSize: '38px',
        marginTop: '2.3rem'
      },
      md: {
        marginTop: '3.2rem',
        fontSize: '2.5rem'
      },
      huge: {
        fontSize: '85px'
      }
    },
    h2: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: 1.35,
      margin: '2em 0',
      fontFamily: 'Raleway',
      sm: {
        margin: '1rem 0 2rem 0',
        fontSize: '20px',
        maxWidth: '90vw'
      }
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: '500',
      fontFamily: 'Raleway',
      fontSize: '14px',
      textAlign: 'center',
      lineHeight: '25px',
      sm: {
        fontSize: '14px',
      }
    },
    h4: {
      fontFamily: 'Montserrat',
      fontSize: '1rem',
      fontWeight: 700,
      sm: {
        fontSize: '14px',
      }
    },
    h5: {
      fontFamily: 'Montserrat',
      fontSize: '18px',
      fontWeight: 700,
      margin: '25px auto 25px auto',
      sm: {
        fontSize: '17px',
        margin: '25px auto 0 auto',
      }
    },
    p: {
      fontSize: '1rem',
      lineHeight: 1.5,
      fontWeight: 400,
      marginBottom: '1rem',
      fontFamily: 'Raleway',
      subCaption: {
        fontSize: '14px'
      }
    },
    footer: {
      link: {
        color: '#fff'
      }
    }
  },
  shape: {
    borderRadius: 50
  },
  props: {
    MuiCheckbox: {
      disableRipple: true,
      color: 'primary',
      size: 'small'
    },
    MuiAppBar: {
      elevation: 0,
      position: 'fixed',
      disableGutters: true
    },
    MuiToolbar: {
      disableGutters: true
    },
    MuiListItem: {
      disableGutters: true
    },
    MuiButton: {
      variant: 'contained',
      disableElevation: true,
      disableRipple: true
    },
    MuiTextField: {

    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 770,
      md: 1024,
      lg: 1215,
      xl: 1440,
    },
  },

});

milanoTheme.overrides = {
  MuiCheckbox: {
    root: {
      color: milanoTheme.palette.primary.main,
    }
  },
  MuiOutlinedInput: {
    multiline: {
      padding: '.5rem 1rem',
      width: '100%',
      minHeight: '80px'
    },
    root: {
      position: 'relative',
      color: "#eee",
      width: '100%',
      dispay: 'inline-flex',
      '& $notchedOutline': {
        borderColor: milanoTheme.palette.primary.main,
        borderRadius: '4px'
      },
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: '#333',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderColor: '#333',
        },
      },
      '&$focused $notchedOutline': {
        borderColor: milanoTheme.palette.primary.dark,
        borderWidth: 2,
      },
    },
  },
  MuiAppBar: {
    root: {
      zIndex: '1301',
      height: '54px',
      '& .contents': {
        maxHeight: '54px',
        [milanoTheme.breakpoints.down('md')]: {
          width: '100%',
        },
        [milanoTheme.breakpoints.between('md','lg')]: {
          width: '960px',
        },
        [milanoTheme.breakpoints.between('lg','xl')]: {
          width: '1100px',
        },
        [milanoTheme.breakpoints.up('xl')]: {
          width: '1150px',
        },
        margin: '0px auto',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '54px',
        '& .toggle': {
          [milanoTheme.breakpoints.down('md')]: {
            display: 'flex',
            height: '54px',
            width: '54px',
            alignItems: 'center',
            justifyContent: 'center'
          },
          [milanoTheme.breakpoints.between('md', 'xl')]: {
            display: 'none',
          },
          '& svg': {
            color: milanoTheme.palette.secondary.contrastTextLight
          }
        },
        '& img': {
          [milanoTheme.breakpoints.down('md')]: {
            margin: '10px 0px 0px .75rem',
          },
          [milanoTheme.breakpoints.up('md')]: {
            margin: '10px 0px 0px 0px',
          },
          width: '186px'
        }
      }
    },
    colorPrimary: {
      backgroundColor: milanoTheme.palette.secondary.main
    }
  },
  MuiToolbar: {
    root: {
      minHeight: '54px',
      maxHeight: '54px',
      '& .menu': {
        alignItems: 'center',
        '& a': {
          fontWeight: 'bold',
          lineHeight: '1.5',
          padding: '.5rem .75rem',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          color: milanoTheme.palette.secondary.contrastText,
          '&:hover': {
            color: milanoTheme.typography.navLink.hover,
          }
        },
        [milanoTheme.breakpoints.down('md')]: {
          display: 'none',
        },
        [milanoTheme.breakpoints.between('md', 'xl')]: {
          display: 'flex',
        },
      }
    },
    regular: {
      minHeight: '54px',
      maxHeight: '54px'
    }
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'none',
    }
  },
  MuiPaper: {
    elevation3: {
      boxShadow: '0 0 60px rgb(1 1 1 / 15%)'
    }
  },
  MuiDrawer: {
    root: {
    },
    paperAnchorTop: {
      background: milanoTheme.palette.secondary.main,
      top: '54px',
      padding: '10px 0px',
      '& .menu': {
        display: 'flex',
        [milanoTheme.breakpoints.down('md')]: {
          flexDirection: 'column',
          marginLeft: '0px',
          alignItems: 'flex-start',
          marginRight: 'auto'
        },
        '& a': {
          textAlign: 'left',
          fontWeight: 'bold',
          lineHeight: '1.5',
          padding: '.5rem .75rem',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          color: milanoTheme.palette.secondary.contrastText,
          '&:hover': {
            color: milanoTheme.typography.navLink.hover,
          }
        },
        '& button': {
          margin: '5px 10px'
        }
      }
    },

  },
  MuiListItem: {
    root: {
      '& a': {
        color: milanoTheme.palette.primary.contrastText,
        cursor: 'pointer',
        textTransform: 'capitalize',
        '&:hover': {
          fontWeight: 'bold'
        },
      },
      '& p': {
        color: '#ccc',
        lineHeight: 1.5,
        [milanoTheme.breakpoints.down(500)]: {
          fontSize: '16px',
          lineHeight: 1.25,
        },
        [milanoTheme.breakpoints.up(500)]: {
          fontSize: '18px',
        },
        fontFamily: 'Montserrat',
      },
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  },
  MuiGrid: {
    root: {
      '& h1': {
        ...milanoTheme.typography.h1,
        color: milanoTheme.palette.primary.contrastText,
        [milanoTheme.breakpoints.down(770)]: {
          fontSize: milanoTheme.typography.h1.sm.fontSize,
        },
      },
      '& h2': {
        ...milanoTheme.typography.h2,
        color: milanoTheme.palette.primary.contrastText,
        [milanoTheme.breakpoints.down(770)]: {
          fontSize: milanoTheme.typography.h2.sm.fontSize,
        },
      },
      '& h3': {
        ...milanoTheme.typography.h3,
        color: milanoTheme.palette.primary.contrastText,
        [milanoTheme.breakpoints.down(770)]: {
          fontSize: milanoTheme.typography.h3.sm.fontSize,
        },
      },
      '& h4': {
        ...milanoTheme.typography.h4,
        color: milanoTheme.palette.primary.contrastText,
        [milanoTheme.breakpoints.down(770)]: {
          fontSize: milanoTheme.typography.h4.sm.fontSize,
        },
      },
      '& h5': {
        ...milanoTheme.typography.h5,
        color: milanoTheme.palette.primary.contrastText,
        [milanoTheme.breakpoints.down(770)]: {
          fontSize: milanoTheme.typography.h5.sm.fontSize,
          margin: milanoTheme.typography.h5.sm.margin,
        },
      },
      '& .open': {
        background: '#1E1E20',
        color: '#ede7cf',
        [milanoTheme.breakpoints.down(770)]: {
          height: '65vh',
        },
        [milanoTheme.breakpoints.up(770)]: {
          height: '400px',
        },
        transition: '.25s linear',
        overflow: 'scroll',
        zIndex: '99999',
      },
      '& .closed': {
        height: '54px',
        color: '#f9f9f9',
        background: '#1E1E20',
        transition: '.25s linear',
        overflow: 'hidden',
        zIndex: '99999',
      },
      '& .burger': {
        width: '30px',
        marginRight: '25px',
        marginLeft: '-55px',
        height: '30px',
      },
      '& .topbar': {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        boxShadow: '0 3px 6px rgba(0,0,0,.15)',
        transition: '15s linear',
        position: 'fixed',
        top: '0px',
        zIndex: '99',
        overflow: 'hidden',
      },
      '& .topbar-items': {
          display: 'flex',
          alignItems: 'center',
          height: '54px',
          justifyContent: 'space-between',
          width: '100vw',
          transition: '.25s linear',
          position: 'fixed',
          overflow: 'hidden',
      },
      '& .dropdown': {
          transition:'.25s linear',
          marginTop: 'auto',
          paddingTop: '55px',
          paddingBottom: '25px',
          textAlign: 'center',
          width: '100%',
          '& a': {
            textDecoration:'none',
            borderBottom: '1px solid #ccc',
            color: '#ccc',
            fontSize: '1rem'
          },
      },
      '& .logo':{
          display: 'block',
          height: '40px',
          cursor: 'pointer',
          margin: '0px auto',
      },
      '& #login-dialog': {
        display: 'none',
        opacity: '0',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& #bug-dialog': {
        display: 'none',
        opacity: '0',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
          color: "#eee",
          marginLeft: '.5rem',
          cursor: 'pointer'
        }
      },
      '& #request-dialog': {
        display: 'none',
        opacity: '0',
        overflowY: 'scroll',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
          color: "#eee",
          marginLeft: '.5rem',
          cursor: 'pointer'
        }
      },
      '& #opportunity-dialog': {
        display: 'none',
        opacity: '0',
        overflowY: 'scroll',
        alignItems: 'center',
        justifyContent: 'center',
        '& p': {
          color: "#eee",
          marginLeft: '.5rem',
          cursor: 'pointer'
        }
      },
      '& .dialog': {
        position: 'fixed',
        display: 'none',
        webkitTransition: 'all .25s linear',
        flexDirection: 'column',
        justifyContent: 'center',
        zIndex: 9999,
        top: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        backdropFilter: 'blur(6px)',
        background: 'rgba(0, 0, 0, 0.5)',
        '& .content': {
          boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
          '&::-webkit-scrollbar': {
            width: '0.5em',
            height: '.25rem'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: '10px',
            backgroundColor: '#393A3C',
            marginLeft: '25px',
            marginRight: '25px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            marginLeft: '15px',
            marginRight: '15px',
            height: '5px',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#AAAAAA',
          },
          borderRadius: '6px',
          maxHeight: '90vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          flexDirection: 'column',
          [milanoTheme.breakpoints.down('sm')]: {
            width: '90vw',
          },
          [milanoTheme.breakpoints.up('sm')]: {
            width: '500px',
          },
          background: '#1E1E20',
          display: 'flex',
          alignItems: 'center',
          paddingBottom: '25px',
          paddingTop: '40px',
          backgroundSize: 'cover',
        },
        '& input': {
          padding: '10px 25px',
          background: 'transparent',
          color: '#fff',
          borderRadius: '6px',
          fontSize: '16px',
        },
        '& button': {
          margin: '10px auto',
          padding: '10px 25px',
          border: 'none',
          outline: 'none',
          webkitAppearance: 'none',
          color: "#fff",
          boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
          borderRadius: '6px',
          '&:hover': {
            cursor: 'pointer',
            background: milanoTheme.palette.primary.dark
          }
        }
      },

      '& .book-dialog': {
        display: 'none',
        flexDirection: 'column',
        width: '100vw',
        background: 'rgba(0, 0, 0, 0.25)',
        left: '0px',
        right: '0px',
        position: 'fixed',
        top: '0px',
        bottom: '0px',
        height: '100vh',
        overflowY: 'scroll',
        backdropFilter: 'blur(8px)',
        '& a' :{
          [milanoTheme.breakpoints.down('md')]: {
            margin: '5vw 5vw 0 auto',
          },
          [milanoTheme.breakpoints.up('md')]: {
            margin: '25px 100px 0 auto',
          },
          textDecoration: 'underline',
          color: "#fff",
          fontSize: '1rem',
          cursor: 'pointer',
          borderRadius: '8px',
          border: '0px',
          padding: 0,
          background: 'transparent',
          webkitAppearance: 'none',
        },
        '& button': {
          [milanoTheme.breakpoints.down('md')]: {
            margin: '5vw 5vw 0 auto',
          },
          [milanoTheme.breakpoints.up('md')]: {
            margin: '75px 100px 0 auto',
          },
          cursor: 'pointer',
          borderRadius: '8px',
          border: '0px',
          padding: 0,
          background: 'transparent',
          webkitAppearance: 'none',
          '& img': {
            height: '30px',
            marginLeft: 'auto',
          }
        },
        '& div': {
          margin: '25px auto',
          borderRadius: '8px',
          [milanoTheme.breakpoints.down('md')]: {
            height: '75vh',
          },
          [milanoTheme.breakpoints.up('md')]: {
            height: '700px',
          },
          overflowY: 'scroll',
          '&::-webkit-scrollbar': {
            width: '0.5em',
            height: '.25rem'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: '10px',
            backgroundColor: '#393A3C',
            marginLeft: '25px',
            marginRight: '25px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '10px',
            marginLeft: '15px',
            marginRight: '15px',
            height: '5px',
            boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
            backgroundColor: '#AAAAAA',
          },
        },
        '& iframe': {
          margin: '0px auto',
          display: 'block',
          border: '0px',
          [milanoTheme.breakpoints.down('md')]: {
            width: '90vw',
          },
          [milanoTheme.breakpoints.up('md')]: {
            width: '500px'
          }
        }
      },
      '& .hero': {
        minHeight: '100vh',
        width: '100%',
        backgroundPosition: '53% 50px',
        marginTop: '-50px',
        [milanoTheme.breakpoints.down('md')]: {
          backgroundSize: 'cover',
          background: "url(./img/hero-small.jpg)",
          backgroundPosition: '53% 50px',
        },
        [milanoTheme.breakpoints.up('md')]: {
          backgroundSize: 'cover',
          background: "url(./img/hero-large.jpg)",
        },
        '& .hero_text': {
          padding: '25vh 25px 25px 25px',
          [milanoTheme.breakpoints.down('md')]: {
          },
          [milanoTheme.breakpoints.up(960)]: {
            float: 'right',
            display: 'block',
            background: 'rgba(0, 0, 0, 0.8)',
            height: '100vh',
            padding: '0 50px',
            maxWidth: '40vw',
            backdropFilter: 'blur(8px)',
          },
          [milanoTheme.breakpoints.between('lg','xl')]: {
            width: '1100px',
          },
        }
      },
      '& .photo-section': {
        display: 'flex',
      },
      '& .average-review-section': {
        display: 'flex',
        flexDirection: 'column',
        '& img': {
          height: '24px'
        },
        '& h1': {
          fontSize: milanoTheme.typography.h1.huge.fontSize,
          margin: '0 0 50px 0'
        },
        '& .star-container': {
          marginBottom: '15px'
        }
      },
      '& .slider-container': {
        maxWidth: '100%',
        paddingBottom: '50px',
        overflowY: 'hidden',
        overflowX: 'scroll',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        '&::-webkit-scrollbar': {
          width: '0.25em',
          height: '.25rem'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
          borderRadius: '10px',
          backgroundColor: '#393A3C',
          marginLeft: '25px',
          marginRight: '25px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          marginLeft: '15px',
          marginRight: '15px',
          height: '5px',
          boxShadow: 'inset 0 0 6px rgba(0,0,0,.3)',
          backgroundColor: '#AAAAAA',
        },
        '& a': {
          textDecoration: 'none',
          display: 'flex',
          height: '25px',
          fontSize: '16px',
          alignItems: 'center',
          justifyContent: 'space-between',
          color: '#aaa',
          margin: '25px auto -25px auto',
          width: '140px'
        }
      },
      '& .review-dialog': {
        display: 'none',
        flexDirection: 'column',
        width: '100vw',
        background: 'rgba(0, 0, 0, 0.25)',
        left: '0px',
        right: '0px',
        position: 'fixed',
        top: '0px',
        bottom: '0px',
        height: '100vh',
        backdropFilter: 'blur(8px)',
        '& button': {
          margin: '75px 100px 0px auto',
          cursor: 'pointer',
          borderRadius: '8px',
          border: '0px',
          padding: '0px',
          background: 'transparent',
          webkitAppearance: 'none',
        },
        '& .reviewFrameContainer': {
          display: 'flex',
          paddingLeft: '15px',
          alignItems: 'center',
          height: '100vh',
          maxWidth: '100vw',
          overflowX: 'scroll',
          textAlign: 'center'
        }
      },
      '& .section': {
          margin: '0px auto',
          padding: '50px 0px',
          '& .centered': {
            textAlign: 'center',
            margin: '0px auto',
          },
          '& .review-box': {
            margin: '0px 15px',
            borderRadius: '8px',
            padding: '15px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0 .5)',
            height: '325px',
            width: '365px',
            display: 'inline-block',
            background: milanoTheme.palette.secondary.light,
            '& img': {
              height: '24px'
            },
            '& .review-head': {
              textAlign: 'center',
            },
            '& .review-body': {
              textAlign: 'center'
            },
            '& .star-container': {
              display: 'inline-block',
              margin: '0px auto',
              width: 'auto',
              justifyContent: 'center',
              paddingBottom: '15px',
              paddingTop: '5px',
              borderBottom: `2px solid ${milanoTheme.palette.secondary.main}`,
            },
            '& p':  {
              ...milanoTheme.typography.p,
              color: milanoTheme.palette.primary.contrastText,
              [milanoTheme.breakpoints.down(770)]: {
                marginTop: '15px',
              },
              overflowWrap: 'break-word',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
              whiteSpace: 'break-spaces',

            }
          },
          '& .divider': {
            [milanoTheme.breakpoints.down(770)]: {
              maxWidth: '150px',
            },
            [milanoTheme.breakpoints.between('sm','xl')]: {
              maxWidth: '100px',
            },
            [milanoTheme.breakpoints.up('xl')]: {
              maxWidth: '125px',
            },
          },
          '& hr': {
            height: '2px',
            border: '0px',
            margin: '0px auto',
            maxWidth: '50vw',
            background: milanoTheme.palette.secondary.light,
          },
        '& .container': {
            margin: '0px auto',
            '& .rent-list': {
              flexBasis: '100%',
              [milanoTheme.breakpoints.up(960)]: {
                flexBasis: '100%',
                paddingLeft: '1rem', paddingTop:' 1rem',paddingBottom: '1rem',
              },
            },
            '& .rent-list-container': {
              margin: '0px auto',
              maxWidth: '100vw',

              [milanoTheme.breakpoints.down(500)]: {
                paddingLeft: '0',
                flexBasis: '80%',
                margin: '0px auto',
                '& hr': {
                  maxWidth: '100%', marginLeft: 0,
                  marginBottom: '.75rem'
                }
              },
              [milanoTheme.breakpoints.between(500,770)]: {
                paddingLeft: '0',
                flexBasis: '80%',
                margin: '0px auto',
                paddingLeft: '2rem',
                '& hr': {
                  maxWidth: '90%', marginLeft: 0,
                  marginBottom: '.75rem'
                }
              },
              [milanoTheme.breakpoints.between(770,960)]: {
                paddingLeft: '0',
                flexBasis: '52%',
                margin: '0px auto',
                paddingLeft: '2rem',
                '& hr': {
                  maxWidth: '90%', marginLeft: 0,
                  marginBottom: '.75rem'
                }
              },
              [milanoTheme.breakpoints.between(960,1215)]: {
                margin: '0px auto',
                paddingLeft: '0',
                '& hr': {
                  maxWidth: '75%', marginLeft: 0,
                  marginBottom: '.75rem'
                }
              },
              [milanoTheme.breakpoints.between('lg','xl')]: {
                margin: '0px auto',
                paddingLeft: '0',
                '& hr': {
                  maxWidth: '60%', marginLeft: 0,
                  marginBottom: '.75rem'
                }
              },
              [milanoTheme.breakpoints.up('xl')]: {
                '& hr': {
                  maxWidth: '60%', marginLeft: 0,
                  marginBottom: '.75rem'

                }
              },
            },
          ' & .just-rent-highlight': {
            background: '#111', margin: '25px auto',
            borderRadius: '4px',
            [milanoTheme.breakpoints.down(770)]: {
              maxWidth: '90vw',
              padding: '20px 40px',
            },
            [milanoTheme.breakpoints.between(500, 770)]: {
              maxWidth: '60vw',
              padding: '20px 40px',
            },
            [milanoTheme.breakpoints.between(770, 960)]: {
              maxWidth: '40vw',
              padding: '20px 10px',
            },
            [milanoTheme.breakpoints.between(960,1024)]: {
              maxWidth: '40vw',
              padding: '20px 50px'
            },
            [milanoTheme.breakpoints.between(1024,1215)]: {
              maxWidth: '30vw',
              padding: '20px 50px'
            },
            [milanoTheme.breakpoints.between('lg','xl')]: {
              maxWidth: '300px',
              padding: '20px 50px',
            },
            [milanoTheme.breakpoints.up('xl')]: {
              maxWidth: '300px',
              padding: '20px 50px',
            },

          },
          '& .photo': {
            backgroundSize: '100%',
            backgroundPosition: '100% 100%',
            [milanoTheme.breakpoints.down(1215)]: {
              width: '42vw',
              height: '42vw',
              margin: '15px auto',
            },
            [milanoTheme.breakpoints.up('md')]: {
              margin: '25px auto',
              flexBasis: '30%',
              height: '350px',
              width: '350px',
            },
          },
          [milanoTheme.breakpoints.down(770)]: {
          },
          [milanoTheme.breakpoints.down('md')]: {
            width: '100%',
          },
          [milanoTheme.breakpoints.between('md','lg')]: {
            width: '960px',
          },
          [milanoTheme.breakpoints.between('lg','xl')]: {
            width: '1100px',
          },
          [milanoTheme.breakpoints.up('xl')]: {
            width: '1150px',
          },
        },
        '& table': {
          margin: '50px auto',
          [milanoTheme.breakpoints.down(770)]: {
            width: '90%',
          },
          [milanoTheme.breakpoints.down('md')]: {
            width: '90%',
          },
          [milanoTheme.breakpoints.up('md')]: {
            width: '75%',
          },
          textAlign: 'left',
          borderSpacing: 0,
          fontSize: '18px',
          color: milanoTheme.palette.primary.contrastText,
          '& th': {
            padding: '10px'
          },
          '& th:nth-child(even)': {
            textAlign: 'right',
          },
          '& td': {
            [milanoTheme.breakpoints.down('md')]: {
              padding: '10px',
            },
            [milanoTheme.breakpoints.up('md')]: {
              padding: '15px',
            },
            borderBottom: `2px solid ${milanoTheme.palette.secondary.light}`
          },
          '& td:nth-child(even)': {
            textAlign: 'right',
          }
        },
      },
      '& .footer': {
        '& img': {
          maxHeight: '25px',
          maxWidth: '25px',
          margin: '10px 5px',
          display: 'inline-block',
          '&:hover': {
            cursor: 'pointer',
          }
        },
        '& a': {
          color: '#aaa',
          display: 'block',
          fontSize: '14px',
          padding: '1rem auto',
          fontFamily: 'Montserrat',
          cursor: 'pointer',
          textTransform: 'uppercase',
          '&:hover': {
            textDecoration: 'underline',
            color: '#fff'
          }
        },
        '& h4': {
          fontFamily: 'Montserrat',
          fontSize: '14px',
          textAlign: 'center',
          textTransform: 'uppercase',
          color: '#ccc',
          marginBottom: '10px'
        },
        '& p': {
          fontFamily: 'Montserrat',
          fontSize: '14px',
          fontWeight: 300,
          color: "#ccc"
        },
        '& .header': {
          width: '90vw',
          height: '25px',
          padding: '15px 0 50px 0',
          borderRadius: '10px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          '& img': {
            height: '35px'
          },

      },
      '& .title': {
        color: '#ede7cf',
        display: 'inline-block',
        fontSize: '28px',
        textAlign: 'center',
      },
      '& div': {
        textAlign: 'center',
        margin: '0px auto',
      },
      '& .listcontainer': {
        width: '90vw',
        margin: '0px auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& div': {
          color: '#ccc',
          minHeight: '150xp',
          fontSize: '22px'
        },
        '& img': {
          maxHeight: '25px',
          maxWidth: '25px',
          margin: '10px 5px',
          display: 'inline-block'
        },
        '& div:nth-child(even)': {
          textAlign : 'right'
        }
      },
      '& .promotion': {
        width: '100%',
        margin: '0px auto',
        color: '#aaa',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '1rem',
        fontSize: '12px',
        paddingTop: '10px',
        paddingBottom: '10px',
        cursor: 'pointer',
        background: 'rgba(0, 0, 0, 0.15)',
        textAlign: 'center',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.35)',
        },
        '& img': {
          height: '12px',
          marginLeft: '.5rem'
        },
        '& p': {
          marginBottom: '50px',
        }
      },
      '& .copyright': {
        width: '100%',
        margin: '0px auto',
        color: '#aaa',
        fontSize: '12px',
        textAlign: 'center',
        paddingBottom: '15px',
        '& p': {
          marginBottom: '15px',
        },
        '& hr': {
          height: '2px',
          border: '0px',
          margin: '0px auto',
          maxWidth: '75vw',
          background: milanoTheme.palette.secondary.light,
        },
      },

      },
    }
  },
  MuiSkeleton: {
    root: {
      backgroundColor: 'rgba(250, 250, 250, 0.7)',
      [milanoTheme.breakpoints.down('md')]: {
        height: '75vh',
        width: '100%'
      },
      [milanoTheme.breakpoints.up('md')]: {
        height: '700px',
        width: '500px'
      },
    }
  },
  MuiButton: {
    root: {
      fontFamily: 'Montserrat',
    },
    containedSizeSmall: {
      padding: 'calc(.375em - 1px) .75em',
      lineHeight: 1.5,
      height: '2.25em',
      letterSpacing: '0px',
      border: '0px solid transparent',
      fontSize: '1rem',
      background: milanoTheme.palette.primary.main,
      color: milanoTheme.palette.primary.contrastText,
      borderRadius: 6,
      '&:hover': {
        background: milanoTheme.palette.primary.dark,
      }
    },
    containedSizeLarge: {
      [milanoTheme.breakpoints.between(0, 770)]: {
        padding: '10px 50px',
        margin: '50px 0 0',
        width: '100%',
        fontSize: '20px',
      },
      [milanoTheme.breakpoints.up('sm')]: {
        padding: '10px 85px',
        fontSize: '16px',
      },
      letterSpacing: '0px',
      fontWeight: 500,
      border: '0px solid transparent',
      textTransform: 'capitalize',
      background: milanoTheme.palette.primary.main,
      color: milanoTheme.palette.primary.contrastText,
      borderRadius: 6,
      '&:hover': {
        background: milanoTheme.palette.primary.dark,
      }
    },
    outlinedSizeLarge: {
      padding: '10px 85px',
      [milanoTheme.breakpoints.between(0, 770)]: {
        width: '100%',
        fontSize: '20px',
        letterSpacing: '1px'
      },
      [milanoTheme.breakpoints.up('sm')]: {
        fontSize: '16px',
        letterSpacing: '1px'
      },
      borderRadius: 6,
      lineHeight: 1.5,
      fontWeight: 500,
      letterSpacing: 0,
      color: milanoTheme.palette.primary.main,
      borderColor: milanoTheme.palette.primary.main,
      '&:hover': {
        background: milanoTheme.palette.primary.main,
        color: milanoTheme.palette.primary.contrastText,
      }
    }
  }
}