import React from 'react'
import ReactGA from 'react-ga'
import { Grid, Button, Typography } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { submitUmamiEvent } from '../tools'

export const Hero = ({ data }) => {
  const isMobile = useMediaQuery('(max-width:770px)')
  const isDesktop = useMediaQuery('(min-width:1024px)')

  const addListener = () => {
    var iframe = document.createElement('iframe')
    iframe.style.display = 'block'
    iframe.src =
      'https://booksy.com/widget/index.html?country=us&lang=en&id=429672&uniqueId=429672-29&mode=inline&theme=lite-booksy-com'
    iframe.id = 'frame'
    document.getElementById('frameContainer').appendChild(iframe)
    const frame = document.getElementById('frame')

    window.addEventListener(
      'message',
      function (e) {
        e && e.data.events && (frame.style.height = e.data.events.resize.height + 'px')
      },
      false
    )

    iframe.onload = () => {
      document.getElementById('frameSkeleton').style.backgroundColor = 'rgba(0, 0, 0, 0)'
      document.getElementById('frameSkeleton').style.height = '0'
      setTimeout(() => {
        document.getElementById('frameSkeleton').style.display = 'none'
      }, 500)
    }
  }

  return (
    <Grid item xs={12} className='hero'>
      <Grid className='hero_text'>
        <Typography style={{ marginTop: isDesktop ? '30vh' : '' }} component='h1'>
          {' '}
          {data.texts.filter(text => text.position === 'title')[0].content}{' '}
        </Typography>
        <Typography component='h2'> {data.texts.filter(text => text.position === 'subtitle')[0].content} </Typography>
        <a
          style={{ display: 'none' }}
          href='https://booksy.com/en-us/429672_jordan-the-barber-milano-barbershop_barber-shop_40249_waukesha'
          target='_blank'
        >
          {' '}
          Book Now{' '}
        </a>
        <Button
          style={{ marginTop: isMobile ? '' : '50px' }}
          size='large'
          onClick={() => {
            document.getElementById('book-dialog').style.display = 'flex'
            addListener()
            ReactGA.event({ category: 'Click', action: 'Clicked book button' })
            submitUmamiEvent('clicked-book-button', {})
          }}
        >
          {' '}
          Book Now{' '}
        </Button>

        {/* <Typography style={{textAlign:'left', color: '#B92C3C', fontSize: '1rem', marginTop: '1rem'}} component='h3' >
        Looking to rent your own studio space?
        <a style={{textDecoration: 'underline', color: '#ccc', marginLeft: '.125rem', display: 'block'}} href='#opportunities'>Learn More</a>
      </Typography> */}

        {/**<a onClick={() => { ReactGA.event({category: 'Click', action: 'Clicked book button'}); window.location = 'https://booksy.com/en-us/429672_jordan-the-barber-milano-barbershop_barber-shop_40249_waukesha' } } > Book Now </a>*/}
        <Typography
          style={isMobile ? { marginTop: '50px' } : { marginTop: '100px', textAlign: 'left' }}
          component='h3'
          dangerouslySetInnerHTML={{
            __html: 'HOURS: <br />' + data.texts.filter(text => text.position === 'hours')[0].content,
          }}
        ></Typography>
      </Grid>
    </Grid>
  )
}
