import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { submitUmamiEvent } from '../tools'

export const Reviews = ({ reviewData }) => {
  return (
    <Grid item xs={12} className='section'>
      <Grid container className='container'>
        <Grid item xs={12}>
          <hr style={{ marginTop: '100px' }} />
          <Grid container className='average-review-section' style={{ margin: '50px auto 100px auto' }}>
            <Grid item xs={12} className='centered'>
              <Typography component='h3' style={{ margin: '0px 0 -40px 0' }}>
                Google Maps
              </Typography>
              <Typography component='h2'>Average Customer Rating</Typography>
            </Grid>
            <Grid item xs={12} className='centered'>
              <Typography component='h1'>{reviewData && reviewData.rating}</Typography>
            </Grid>
            <Grid item xs={12} className='centered'>
              <Grid className='star-container'>
                {Array.from(Array(5)).map((e, index) => {
                  return <img alt='' key={index} src='./img/star.svg' />
                })}
              </Grid>
            </Grid>
            <Typography component='h3'>Based on {reviewData && reviewData.user_ratings_total} reviews</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} className='slider-container' id='review-slider-container'>
              {reviewData &&
                reviewData.reviews.map((review, index) => {
                  if (review.rating === 5) {
                    return (
                      <Grid key={review.author_name} container style={{ paddingRight: '55px', paddingLeft: '55px' }}>
                        <Grid
                          item
                          xs={12}
                          style={{ minWidth: '315px' }}
                          className='review-box'
                          key={
                            review.author_name
                          } /** onClick={ () => { document.getElementById('review-dialog').style.display = 'flex';console.log(index);document.getElementById('review-frame').scrollLeft = (window.innerWidth*(index-1))}} */
                        >
                          <Grid container className='review-head'>
                            <Grid item xs={6} className='star-container'>
                              {Array.from(Array(5)).map((e, index) => {
                                return <img alt='' key={index} src='./img/star.svg' />
                              })}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography component='h5'> {review.author_name} </Typography>
                            </Grid>
                          </Grid>
                          <Grid container className='review-body'>
                            <Grid item xs={12} style={{ alignItems: 'center' }}>
                              <Typography component='p'>
                                {' '}
                                {review.text.length > 215 ? review.text.substring(0, 215) + '...' : review.text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <a
                            href={
                              review.author_url.substring(0, review.author_url.length - 7) +
                              'place/ChIJFdi3CN2nBYgRRwi8NNL7VRk'
                            }
                            target='_blank'
                            style={{}}
                          >
                            <img alt='' style={{ width: '13px' }} src='./img/google.svg' />
                            <Typography
                              onClick={() => {
                                submitUmamiEvent('clicked-open-review', {})
                              }}
                              component='p'
                            >
                              Read on Google
                            </Typography>
                          </a>
                        </Grid>
                      </Grid>
                    )
                  }
                })}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className='centered'>
              <Typography component='h3' style={{ margin: '50px 0 0px 0' }}>
                Powered by
              </Typography>
              <Typography component='h3' style={{ fontSize: '18px' }}>
                Google Places
              </Typography>
              <hr style={{ marginTop: '50px' }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
