import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Edit from "./Edit.js";
import Info from "./Info.js";
import PageContent from "./PageContent";

function RouteContainer() {
  return (
    <Router>
      <Switch>
        <Route path="/edit">
          <Edit />
        </Route>
        <Route path="/info">
          <Info />
        </Route>
        <Route path="/">
          <PageContent />
        </Route>
      </Switch>
    </Router>
  );
}

export default RouteContainer;
