import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { gql, useQuery } from "@apollo/client";

const BUGS = gql`
  query Bug {
    bugs {
      boxes
      description
      createdAt
    }
  }
`;

export const Bugs = () => {
  const { loading, error, data } = useQuery(BUGS);
  if (loading) return "loading";
  if (error) return "error";

  return (
    <Grid item xs={12} className="section">
      <Grid container className="container">
        <Grid item xs={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              className="slider-container"
              id="review-slider-container"
            >
              {data &&
                data.bugs.map((bug, index) => {
                  return (
                    <Grid
                      key={bug.name}
                      container
                      style={{ paddingRight: "55px", paddingLeft: "55px" }}
                    >
                      <Grid
                        item
                        xs={12}
                        className="review-box"
                        style={{ height: "auto" }}
                        key={
                          bug.name
                        } /** onClick={ () => { document.getElementById('review-dialog').style.display = 'flex';console.log(index);document.getElementById('review-frame').scrollLeft = (window.innerWidth*(index-1))}} */
                      >
                        <Grid container className="review-head">
                          <Grid
                            item
                            xs={6}
                            className="star-container"
                            style={{ textAlign: "center" }}
                          >
                            <Typography component="h5">
                              {" "}
                              {new Date(bug.createdAt).toString()}{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography component="h5">
                              {" "}
                              {bug.boxes}{" "}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container className="review-body">
                          <Grid
                            item
                            xs={12}
                            style={{ alignItems: "center", marginTop: "1rem" }}
                          >
                            <Typography component="p">
                              {" "}
                              {bug.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
