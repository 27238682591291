import React, { useEffect, useState } from "react";
import "./edit.css";
import EditPhotoSection from "./Components/EditPhotos";
import { gql, useQuery, useMutation } from "@apollo/client";

const EDIT_TEXT = gql`
  mutation EditText(
    $position: String
    $content: String
    $type: String
    $price: String
    $id: ID
  ) {
    editText(
      position: $position
      content: $content
      price: $price
      type: $type
      id: $id
    ) {
      id
      position
      content
      price
      type
    }
  }
`;

const CREATE_SERVICE = gql`
  mutation CreateService($position: String!) {
    createService(position: $position) {
      position
    }
  }
`;

const DELETE_SERVICE = gql`
  mutation DeleteService($id: ID!) {
    deleteService(id: $id) {
      id
    }
  }
`;

const TEXT = gql`
  query Text {
    texts {
      position
      id
      content
      price
      type
    }
  }
`;

const Edit = () => {
  const [editText] = useMutation(EDIT_TEXT);
  const [createService] = useMutation(CREATE_SERVICE);
  const [deleteService] = useMutation(DELETE_SERVICE);
  const [changes, setChanges] = useState();

  function openNav(e) {
    let topbar = document.getElementById("topbar");
    topbar.classList.toggle("open");
    topbar.classList.toggle("closed");
  }

  useEffect(() => {
    if (!localStorage.getItem("credentials")) {
      window.location = "/";
    }
  }, []);

  const handleChanges = (e) => {
    const { id, innerText } = e.target;
    setChanges((old) => ({ ...old, [id]: innerText }));
  };

  const updateText = () => {
    Object.entries(changes).map((change) => {
      console.log(changes);
      if (change[1].includes("$")) {
        editText({ variables: { id: change[0], price: change[1] } }).then(
          (res) => {
            const { [change[0]]: removed, ...remaining } = changes;
            setChanges(false);
            refetch();
          }
        );
      } else {
        editText({ variables: { id: change[0], content: change[1] } }).then(
          (res) => {
            const { [change[0]]: removed, ...remaining } = changes;
            setChanges(false);
            console.log(changes);
            refetch();
          }
        );
      }
    });
  };

  const { loading, error, data, refetch } = useQuery(TEXT);
  if (loading) return "loading";
  if (error) return "error";

  console.log(data.texts.filter((text) => text.type === "table"));

  return (
    <div className="mainwrapper">
      <div id="edit-head">
        <span> ADMIN MODE </span>
        <button
          onClick={() => {
            window.location = "/";
            localStorage.removeItem("credentials");
          }}
        >
          {" "}
          Logout{" "}
        </button>
        {changes && (
          <div id="save-button" onClick={updateText}>
            {" "}
            Save Changes{" "}
          </div>
        )}
      </div>

      <div className="closed" id="topbar">
        <div id="topbar-items">
          <img
            alt=""
            onClick={() => window.location.reload()}
            className="logo"
            src="https://jordanthebarber.com/wp-content/uploads/2021/03/cropped-Milano-Barbershop-Logo-2-1.png"
          />
          <img
            alt=""
            onClick={openNav}
            className="burger"
            src="../img/bars-solid.svg"
          />
        </div>
        <div id="dropdown">
          <button onClick={openNav} name="about" to="/login">
            {" "}
            ADMIN{" "}
          </button>
        </div>
      </div>
      <div id="contentWrapper">
        <div id="hero">
          <div id="hero-text">
            <h1
              className="editable-text"
              id={data.texts.filter((text) => text.position === "title")[0].id}
              contentEditable="true"
              onInput={handleChanges}
            >
              {
                data.texts.filter((text) => text.position === "title")[0]
                  .content
              }
            </h1>
            <p
              className="editable-text"
              id={
                data.texts.filter((text) => text.position === "subtitle")[0].id
              }
              contentEditable="true"
              onInput={handleChanges}
            >
              {" "}
              {
                data.texts.filter((text) => text.position === "subtitle")[0]
                  .content
              }{" "}
            </p>
            <a
              /** onClick={() => ReactGA.event({action: 'Rated an App'})} */ href="https://booksy.com/en-us/429672_jordan-the-barber-milano-barbershop_barber-shop_40249_waukesha"
              target="_blank"
            >
              {" "}
              Book Now{" "}
            </a>
            <span
              className="editable-text"
              id={data.texts.filter((text) => text.position === "hours")[0].id}
              contentEditable="true"
              onInput={handleChanges}
              dangerouslySetInnerHTML={{
                __html:
                  "HOURS: <br />" +
                  data.texts.filter((text) => text.position === "hours")[0]
                    .content,
              }}
            ></span>
          </div>
        </div>
        <div id="services-section">
          <img alt="" className="divider-image" src="./img/scissors.svg" />
          <h1
            className="editable-text"
            id={
              data.texts.filter((text) => text.position === "divider-one")[0].id
            }
            contentEditable="true"
            onInput={handleChanges}
          >
            {
              data.texts.filter((text) => text.position === "divider-one")[0]
                .content
            }
          </h1>
          <hr />
          <div className="item-box">
            <div id="services-table">
              <table>
                <tbody>
                  <tr>
                    <th> Service: </th>
                    <th> Price: </th>
                  </tr>
                  {data.texts
                    .filter((text) => text.type === "table")
                    .map((item, index) => {
                      return (
                        <tr id={index} key={index}>
                          <td
                            className="editable-table"
                            id={item.id}
                            contentEditable="true"
                            onInput={handleChanges}
                            style={{
                              borderBottom:
                                index ===
                                  data.texts.filter(
                                    (text) => text.type === "table"
                                  ).length -
                                    1 && "0px",
                            }}
                          >
                            {item.content}
                          </td>
                          <td
                            className="editable-table"
                            id={item.id}
                            contentEditable="true"
                            onInput={handleChanges}
                            style={{
                              borderBottom:
                                index ===
                                  data.texts.filter(
                                    (text) => text.type === "table"
                                  ).length -
                                    1 && "0px",
                            }}
                          >
                            {item.price}
                            <div
                              style={{ float: "right", marginRight: "-35px" }}
                              id={item.id}
                              onClick={() => {
                                deleteService({
                                  variables: { id: item.id },
                                }).then(() => refetch());
                              }}
                            >
                              <img
                                alt=""
                                style={{ height: "25px" }}
                                src="./img/home/close.svg"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <button
                onClick={() => {
                  createService({
                    variables: {
                      position:
                        "t" +
                        (data.texts.filter((text) => text.type === "table")
                          .length +
                          1),
                    },
                  }).then(refetch());
                }}
              >
                {" "}
                Add Service{" "}
              </button>
            </div>
          </div>
          <hr />
          <h2
            className="editable-text"
            contentEditable="true"
            id={
              data.texts.filter((text) => text.position === "divider-two")[0].id
            }
            onInput={handleChanges}
          >
            {
              data.texts.filter((text) => text.position === "divider-two")[0]
                .content
            }
          </h2>
          <img alt="" className="divider-image" src="./img/razor.svg" />
        </div>

        <div id="review-section">
          <div id="review-slider-container">
            <div className="review">
              <div className="review-head">
                <div className="star-container">
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                </div>
                <h1> David Lattos </h1>
              </div>
              <div className="review-body">
                <span> Most talented barber I’ve ever been to, period </span>
              </div>
            </div>

            <div className="review">
              <div className="review-head">
                <div className="star-container">
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                </div>
                <h1> William Figueroa </h1>
              </div>
              <div className="review-body">
                <span>
                  {" "}
                  Jordans a barber the bus barber in Waukesha professional
                  barber{" "}
                </span>
              </div>
            </div>

            <div className="review">
              <div className="review-head">
                <div className="star-container">
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                </div>
                <h1> Daniel Howard </h1>
              </div>
              <div className="review-body">
                <span>
                  {" "}
                  I really enjoyed the barbershop very good cuts customer
                  service is number one please do come{" "}
                </span>
              </div>
            </div>

            <div className="review">
              <div className="review-head">
                <div className="star-container">
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                  <img alt="" src="./img/star.svg" />
                </div>
                <h1> Brandon Gorski </h1>
              </div>
              <div className="review-body">
                <span>
                  {" "}
                  My guy Jordan the barber is crazy talented! Different style of
                  barbershop for Waukesha at a good price{" "}
                </span>
              </div>
            </div>
          </div>

          <p>Powered by Google Reviews</p>
          <hr />
        </div>
        <EditPhotoSection />
      </div>
      <div id="footer">
        <div className="header">
          <img alt="" src="./img/m.png" />
        </div>
        <div className="listcontainer">
          <div style={{ width: "100vw" }}>
            <h1>Hours</h1>
            <p
              className="editable-text"
              id={data.texts.filter((text) => text.position === "hours")[0].id}
              contentEditable="true"
              onInput={handleChanges}
              dangerouslySetInnerHTML={{
                __html: data.texts.filter(
                  (text) => text.position === "hours"
                )[0].content,
              }}
            />
          </div>
          <div>
            <h1>Contact</h1>
            <p
              className="editable-text"
              contentEditable="true"
              id={data.texts.filter((text) => text.position === "phone")[0].id}
            >
              {" "}
              {
                data.texts.filter((text) => text.position === "phone")[0]
                  .content
              }
            </p>
            <p
              className="editable-text"
              contentEditable="true"
              id={
                data.texts.filter((text) => text.position === "address")[0].id
              }
            >
              {" "}
              {
                data.texts.filter((text) => text.position === "address")[0]
                  .content
              }
            </p>
          </div>
          <div>
            <h1>Follow Us</h1>
            <img alt="" src="./img/home/facebook.svg" />
            <img alt="" src="./img/home/instagram.svg" />
          </div>
        </div>
        <div className="copyright">© Milano Barbershop 2021</div>
      </div>
    </div>
  );
};

export default Edit;
