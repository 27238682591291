import React, { useState, useRef } from 'react';
import '../edit.css'
import { gql, useQuery, useMutation } from '@apollo/client'
import axios from 'axios'

const PHOTOS = gql`
  query Photos {
    photos {
      position
      url
    }
  }`

const EDIT_PHOTO = gql `
  mutation EditPhoto ($position: String!, $url: String!) {
    editPhoto(position: $position, url: $url) {
      position
      url
    }
  }`

const EditPhotoSection = ({ refetch }) => {
  const [uploadProgress, setUploadProgress] = useState()
  const [uploading, setUploading] = useState()
  const [photoPosition, setPhotoPosition] = useState(0)
  const [preview, setPreview] = useState(false)
  const fileBox = useRef()
  const reader = new FileReader()
  const [editPhoto] = useMutation(EDIT_PHOTO)

  const photoSuccessNotify = () => {
    document.getElementById('success-dialog').style.display = 'flex'
    setTimeout(() => {
      document.getElementById('success-dialog-content').style.marginTop = '100px'
    }, 0)
    setTimeout(() => {
      document.getElementById('success-dialog-content').style.marginTop = '-200px'
    }, 1000)
    setTimeout(() => {
      document.getElementById('success-dialog').style.display = 'none'
      refetch()
    }, 1200)
  }

  const UploadDialog = () => {
    return <div id='upload-dialog' style={{display: preview? 'flex': 'none'}}>
      {uploading && <img alt='' src='./img/home/loading.gif' style={{marginTop: '-100px', width: '100px'}} /> }
      <div className='content' style={{background: 'url(' + preview + ')'}}>
        <button onClick={() => setPreview(false)}> Cancel </button>
        <button onClick={uploadPhoto}> Upload </button>
      </div>
    </div>
  }

  const SuccessDialog = () => {
    return <div id='success-dialog'>
      <div id='success-dialog-content' className='content' >
        Upload Successfull!
      </div>
  </div>
  }

  const createPreview = () => {
    reader.readAsDataURL(fileBox.current.files[0])
  }

  reader.addEventListener('load', function () {
    setPreview(reader.result)
  }, false)

  const uploadPhoto = () => {
    setUploading(true)
      axios({
        method: 'post',
        url: 'https://api.imgur.com/3/image',
        headers: {
          Authorization: 'Bearer 69bfb51c8ed18bc770955a370d789235b2d76511',
        },
        data: {
          image: preview.substring(22),
          album: '6uXjqHm',
        },
        onUploadProgress: (data) => setUploadProgress(Math.round((100 * data.loaded) / data.total))
      }).then(res => {

        setPreview()
        setUploading(false)
        editPhoto({variables: {position: photoPosition, url: res.data.data.link}}).then(res => photoSuccessNotify())
      })
  }

  const { loading, error, data } = useQuery(PHOTOS)
	if (loading) return 'loading'
	if (error) return 'error'

  return <>

  <UploadDialog />
  <SuccessDialog />

  <div id='photo-section-edit'>
  {data && data.photos.map((photo) => {
    return <div style={{background: 'url(' + photo.url + ')'}} >
      <div className='edit-button'>
        <button onClick={() => { fileBox.current.click(); setPhotoPosition(photo.position) }}> Upload New <img alt='' src='./img/home/upload.svg' />
        </button>
      </div>
    </div>
  })}

<input style={{display: 'none'}} ref={fileBox} onChange={createPreview} type='file' />

</div></>

}

export default EditPhotoSection